import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import { LoginForm } from 'components/Forms/Login';
export function Auth({ isPopup, customRedirect }) {
  const isMobile = useMediaQuery('(max-width: 639px)');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const cookies = new Cookies();
    cookies.set('loginTo', isPopup ? `${window.location.pathname}${window.location.search}` : '/dashboard/properties', {
      path: '/',
    });
  }, [isPopup]);

  return isMobile ? (
    <LoginForm mobile customRedirect={customRedirect} />
  ) : (
    <div className="bg-white-50 flex flex-col align-items-center rounded-2xl">
      <div className="flex flex-col text-center">
        <LoginForm customRedirect={customRedirect} />
      </div>
    </div>
  );
}

Auth.propTypes = {
  showcasesData: PropTypes.object,
  getShowcases: PropTypes.func,
};

export default Auth;
