import http from '../http';

/**
 * Building Api Service
 */
export class OrganisationService {
  urlPrefix = 'api/listing';

  getAllOrganisationNames() {
    return http.get(`${this.urlPrefix}/organisations`);
  }

  getOrganisationById(orgId) {
    return http.get(`${this.urlPrefix}/organisations/${orgId}`);
  }

  updateOrganisation(data) {
    return http.put(`${this.urlPrefix}/update-organisation/`, data);
  }
}

export const OrganisationApi = new OrganisationService();
