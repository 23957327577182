import { useState } from 'react';

import { FormControl, Icon, InputGroup } from 'rsuite';

function PasswordInput({ ...props }) {
  const [type, setType] = useState('password');

  const toggletype = () => {
    setType(type === 'password' ? 'text' : 'password');
  };

  return (
    <InputGroup style={{ width: 'auto', zIndex: '1' }} {...props} inside>
      <FormControl {...props} type={type} placeholder={type === 'password' ? '*******' : 'password...'} />
      <InputGroup.Addon>
        <div onClick={toggletype}>
          <Icon style={{ cursor: 'pointer' }} icon={type === 'password' ? 'eye' : 'eye-slash'} className="p-3" />
        </div>
      </InputGroup.Addon>
    </InputGroup>
  );
}

export default PasswordInput;
