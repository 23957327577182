import React, { useEffect } from 'react';

const ChatbotHide = ({ show }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      setTimeout(() => {
        let chatbot = document.getElementById('LeadboosterContainer');
        if (show == true) {
          if (chatbot == null) {
            // location.reload();
          }
        } else {
          if (chatbot != null) {
            document.getElementById('LeadboosterContainer').remove();
          }
        }
      }, 10000);
    }
  }, [show]);

  return <div></div>;
};

export default ChatbotHide;
