import ForgotPasswordForm from 'components/Forms/ForgotPassword';
import Image from 'next/image';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import Link from 'next/link';
import { ChevronLeft } from 'react-feather';
export function Auth() {
  const isMobile = useMediaQuery('(max-width: 639px)');

  return (
    <div className={`h-screen w-full flex flex-col ${isMobile ? 'bg-white-50' : ''} `}>
      {!isMobile ? (
        <div className="flex justify-center lg:justify-start pt-5 mt-6 lg:mt-0 pl-10">
          <Link href="/login">
            <Image
              src="/static/images/leni-logo-black.svg"
              height={50}
              width={200}
              className="hover:cursor-pointer"
              alt="Leni logo"
            />
          </Link>
        </div>
      ) : (
        <div className="flex justify-between items-center py-8 px-12 flex-stretch">
          <Link href="/login">
            <ChevronLeft height={20} width={20} className="text-gray-800 hover:cursor-pointer" />
          </Link>
          <Link href="/login">
            <Image
              height={50}
              width={50}
              src="/static/images/leni-logo-black.svg"
              className="hover:cursor-pointer"
              alt="Leni logo"
            />
          </Link>
        </div>
      )}
      <ForgotPasswordForm mobile={isMobile} />
    </div>
  );
}

export default Auth;
