import * as React from 'react';

import { cn } from '@/lib/utils';
import { AlertCircle } from 'react-feather';
import { FieldError } from 'react-hook-form';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  error?: FieldError | undefined;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, icon, type, error = undefined, ...props }, ref) => {
    return (
      <div
        className={cn(
          `flex h-10 items-center rounded-md border-[0.5px] ${
            error ? 'border-error-500' : 'border-input'
          } bg-white pl-3 text-sm ring-offset-background focus-within:ring-4 focus-within:ring-${
            error ? 'error' : 'primary'
          }-100 focus-within:ring-offset-0 focus-within:border-${error ? 'error' : 'primary'}-200`,
          className
        )}>
        {icon && icon}
        <input
          {...props}
          type={type}
          ref={ref}
          className={`w-full p-2 placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50`}
        />
        {error && <AlertCircle className="h-[16px] w-[16px] text-error-500 mr-2" />}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
