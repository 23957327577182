import { useState, useEffect } from 'react';
import { AuthApi } from 'services/api';
import Link from 'next/link';
import ContactSupportModal from 'components/Modals/ContactSupport';
import { Input } from '@/components/ui/input.tsx';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components/ui/form.tsx';
import { Button } from '@/components/ui/button.tsx';
import { CheckCircle, Mail } from 'react-feather';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Cookies from 'universal-cookie';
import { useForm } from 'react-hook-form';
export function ForgotPasswordForm({ isPopup, mobile = false }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const cookies = new Cookies();
    cookies.set('loginTo', isPopup ? `${window.location.pathname}${window.location.search}` : '/dashboard/bookings', {
      path: '/',
    });
  }, [isPopup]);

  const [verificationLinkSent, setVerificationLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setVerificationLinkSent(prev => {
      return prev ? false : true;
    });
  };

  const forgotPasswordFormSchema = z.object({
    email: z.string().email({
      message: 'Please enter a valid email address',
    }),
  });
  const forgotPasswordForm = useForm({
    resolver: zodResolver(forgotPasswordFormSchema),
    defaultValues: {
      email: '',
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    setError,
  } = forgotPasswordForm;

  const onSubmit = async values => {
    setLoading(true);
    try {
      const { email } = values;
      await AuthApi.generateResetPasswordLink({ email });
      toggleModal();
    } catch (error) {
      setError('email', {
        message: "Oops! It seems this user doesn't exist. Please try another email.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`h-[100%] flex items-center justify-center mb-0 sm:mb-48 lg:mb-0 ${mobile && 'mt-20'}`}>
      <div
        className={`self-${
          mobile ? 'start' : 'center'
        } bg-white-50 px-12 pt-10 pb-8 w-[500px] flex flex-col rounded-2xl h-[${errors?.email ? '450' : '400'}px]`}>
        {!verificationLinkSent ? (
          <>
            <div className="pb-5 text-center">
              <h2 className="display-md text-black mb-2">Reset your password</h2>
              <span className="text-lg">Let&apos;s get you back in the game!</span>
            </div>
            <Form {...forgotPasswordForm}>
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 mb-6">
                <FormField
                  className=""
                  control={forgotPasswordForm.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="text-left">
                      <FormLabel className="text-xs text-gray-500">Email</FormLabel>
                      <FormControl
                        className={`focus:outline-none active:outline-none ${errors?.email && 'border-error-500'}`}>
                        <Input
                          icon={<Mail className="h-[16px] w-[16px] text-gray-400" />}
                          placeholder="john.doe@gmail.com"
                          error={errors?.email}
                          aria-invalid={errors?.email ? 'true' : 'false'}
                          {...field}
                          className="focus:outline-none active:outline-none"
                          type="email"
                        />
                      </FormControl>
                      {errors?.email && (
                        <FormDescription className="text-error-500 text-xs">*{errors?.email.message}</FormDescription>
                      )}
                    </FormItem>
                  )}
                />
                <Button
                  className="text-black bg-accent-300 cursor-pointer hover:bg-accent-200 my-6 w-full"
                  size="sm"
                  disabled={loading}>
                  Send recovery link
                </Button>
              </form>
            </Form>
          </>
        ) : (
          <div className="flex flex-col items-center">
            <div className="bg-success-100 w-20 h-20 flex items-center justify-center rounded-full mb-6">
              <CheckCircle height={50} width={50} className="text-success-500" />
            </div>
            <h2 className="display-md text-primary-900 mb-4">Recovery link sent</h2>
            <p className="text-primary-900 text-lg text-center mb-8">
              Please check your email for the recovery password link
            </p>
          </div>
        )}
        <Link href="/login">
          <span className="hover:cursor-pointer text-center font-bold">Back to login</span>
        </Link>
        {!verificationLinkSent && (
          <span className={`text-center mt-6 ${mobile ? 'flex flex-col gap-0' : ''}`}>
            Haven&apos;t received the link? <ContactSupportModal />
          </span>
        )}
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
