import * as React from 'react';
import { Eye, EyeOff, AlertCircle } from 'react-feather';
import { cn } from '@/lib/utils';

const PasswordInput = React.forwardRef(({ className, error, icon, ...props }, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <div
      className={cn(
        `flex h-10 items-center rounded-md border border-input bg-white pl-3 text-sm ring-offset-background focus-within:ring-4 focus-within:ring-primary-100 focus-within:ring-offset-0 focus-within:border-primary-200`,
        className
      )}>
      {icon && icon}
      <input
        {...props}
        type={showPassword ? 'text' : 'password'}
        ref={ref}
        className="w-full p-2 placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
      />
      {!error && showPassword && (
        <Eye className="h-[16px] w-[16px] text-gray-400 cursor-pointer mr-2" onClick={() => setShowPassword(false)} />
      )}
      {!error && !showPassword && (
        <EyeOff className="h-[16px] w-[16px] text-gray-400 cursor-pointer mr-2" onClick={() => setShowPassword(true)} />
      )}
      {error && <AlertCircle className="h-[16px] w-[16px] text-error-500 mr-2" />}
    </div>
  );
});
PasswordInput.displayName = 'Input';

export { PasswordInput };
