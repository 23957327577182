import * as React from 'react';

import { cn } from '@/lib/utils';

const Textarea = React.forwardRef(({ className, error, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        `flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-within:ring-4 focus-within:ring-${
          error ? 'error' : 'primary'
        }-100 focus-within:ring-offset-0 focus-within:border-${
          error ? 'error' : 'primary'
        }-200 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50 active:border-none focus:border-none active:outline-none focus:outline-none`,
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
