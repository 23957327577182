import { useState } from 'react';
import { Alert } from 'rsuite';
import { AuthApi, UserApi } from 'services/api';
import Form from '../../components/Forms/index';
import { Router } from 'utils/with-i18next';
import { createErrorStatusCodeHandler } from 'utils/default-http-error-handler';
import { useErrorTracker } from 'utils/use-error-tracker';

export function Auth({
  isPopup,
  setformAction,
  mutateUser,
  userType,
  setEmail = '',
  tenantSignup = false,
  onAccountCreated,
}) {
  const errorTracker = useErrorTracker();
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    email: setEmail, //If email is provided, set it as default value, used in tenant-login page flow
    firstName: '',
    lastName: '',
    organisationName: '',
    password: '',
    confirmPassword: '',
    adminPassword: '',
    views: [],
    signInType: userType,
    userType: userType,
    dashboardViews: [],
  });

  const isErrorResponse = function (response) {
    return response && response.data && response.data.status > 200;
  };

  const getErrorMessageFromResponse = function (response) {
    return response && response.data && response.data.message;
  };

  const onFormSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    // adding the default value 'properties' for views
    // If user has analytics plus, 5 standard dashboards take priority. Otherwise, if they have analytics they get overview leasing and marketing
    if (formValue.views.includes('analytics-plus')) {
      formValue.dashboardViews.push(
        'standard_operations',
        'standard_occupancy_and_leasing_performance',
        'standard_marketing',
        'standard_financial'
      );
    } else if (formValue.views.includes('dashboard')) {
      formValue.dashboardViews.push('overview', 'leasing', 'marketing');
    }
    // If user has price+, revenue management is added to their dashboard views (for now add reporting until we are able to update hardcoded cases and can use standard_revenue_management naming)
    if (formValue.views.includes('price+')) {
      formValue.dashboardViews.push('reporting');
    }

    // Separate the values without pages to prevent the being first
    const valuesWithoutPages = ['sage+', 'application-module', 'credit-checks', 'price+', 'analytics-plus'];
    const startViews = formValue.views.filter(view => !valuesWithoutPages.includes(view));
    const endViews = formValue.views.filter(view => valuesWithoutPages.includes(view));

    const signUpData = { ...formValue, views: [...startViews, ...endViews, 'properties'] };
    AuthApi.signup(signUpData)
      .then(response => {
        if (!isErrorResponse(response)) {
          Alert.success('Account Created');
          if (!isPopup) {
            Router.push('/login');
          } else {
            UserApi.getCurrentUser()
              .then(user => {
                mutateUser(user);
                // Use special redirect behavior for tenant signup page, will assign booking to new user and redirect them to my booking page for it
                if (tenantSignup) {
                  onAccountCreated(user);
                }
              })
              .catch(err => {
                errorTracker.error('Get current user error');
                Alert.error('Something went wrong while fetching user data.', 5000);
                console.error(err);
              });
          }
        } else {
          throw getErrorMessageFromResponse(response);
        }
      })
      .catch(
        createErrorStatusCodeHandler(
          'sign up',
          () => {
            Alert.error(`User with that email already exists, try logging in!`, 5000);
          },
          () => {
            setLoading(false);
          },
          errorTracker,
          { signUpData: signUpData }
        )
      );
  };

  return (
    <Form
      signup
      titleText="Let's get started"
      buttonText="Sign Up"
      belowButtonText={
        <>
          Already have an account! <strong>Login</strong>
        </>
      }
      belowButtonLink="/login"
      handleSubmit={onFormSubmit}
      isLoading={loading}
      onChangeFormValue={setFormValue}
      formValue={formValue}
      isPopup={isPopup}
      setformAction={setformAction}
      userType={userType}
      setEmail={setEmail}
      showLink={!tenantSignup}
    />
  );
}

export default Auth;
