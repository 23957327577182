export const getDefaultRedirectPage = (user, showTableau = false) => {
  // set default redirect page to the first element within user views
  // if this happens to be price, redirect to /get-rent-estimate
  // otherwise, if it is not dashboard, redirect to /dashboard/{first_element_in_user_views}
  // default: redirect to /dashboard
  if (!user) {
    return '/login';
  }
  if (user && user.userType !== 'landlord') {
    return '/properties';
  }
  if (user && user.userType === 'landlord') {
    // if (!user.views) return '/dashboard/properties';
    if (user.views.includes('bookings')) return '/dashboard/bookings';
    if (user.views.includes('dashboard')) {
      if (user.dashboardViews.length === 0 && user.views.includes('price+'))
        return '/dashboard/analytics?page=reporting';
      if (showTableau) return '/dashboard/analytics?page=executiveSummary';
    } else {
      return '/dashboard/properties';
    }
  }
  return '/dashboard/properties';
};
